import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuComponent} from './menu.component';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './footer/footer.component';
import {SharedModule} from '../shared/shared.module';
import {HeaderMenuBuilder} from './builder/header-menu-builder';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        MenuComponent,
        FooterComponent
    ],
    exports: [
        FooterComponent,
        MenuComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
    ],
    providers: [
        HeaderMenuBuilder,
    ]
})
export class MenuModule {
}
