<div class="relative isolate px-6 pt-14 lg:px-8 bg-slate-700">
  <div class="absolute inset-x-0 -z-10 overflow-hidden w-full h-full">
    <app-lines [amountOfLines]="32"></app-lines>
  </div>
  <div class="mx-auto max-w-3xl py-32 sm:py-48 lg:py-56">
    <div class="text-center">
      <h1 class="text-5xl font-bold tracking-tight text-white sm:text-5xl" data-aos="fade-up" data-aos-duration="1000" [innerHTML]="'landing.hero.subtitle' | translate"></h1>
      <p class="mt-6 text-lg leading-8 text-slate-400" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="landing.hero.title"></p>
      <p class="mt-4 text-semibold text-lg leading-8 text-white" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="landing.hero.claim"></p>
      <div class="mt-10 flex items-center cursor-pointer justify-center gap-x-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
        <div class="inline-block mx-auto relative">
          <div (click)="scrollToElement(target)" class="text-sm font-semibold leading-6 text-white scroll" style="padding-left: 28px" translate="landing.hero.button"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="relative">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
</div>

<div class="bg-white py-24 sm:py-32 pt-16 sm:pt-16" #target>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
      <p class="text-base font-semibold leading-7 text-teal-600" translate="landing.features.title"></p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="landing.features.subtitle"></h1>
      <div class="mx-auto mt-8 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div class="flex flex-col">
            <dt class="text-base font-semibold leading-7 text-gray-900" translate="landing.features.interchange.heading"></dt>
            <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto" translate="landing.features.interchange.text"></p>
              <p class="mt-6">
                <a href="/xchangehub" class="text-sm font-semibold leading-6 text-teal-600"><span class="mr-2" translate="landing.features.interchange.button"></span> <span aria-hidden="true">→</span></a>
              </p>
            </dd>
          </div>
          <div class="flex flex-col">
            <dt class="text-base font-semibold leading-7 text-gray-900" translate="landing.features.consulting.heading"></dt>
            <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto" translate="landing.features.consulting.text"></p>
              <p class="mt-6">
                <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3/group/11/create/42" target="_blank" class="text-sm font-semibold leading-6 text-teal-600"><span class="mr-2" translate="landing.features.consulting.button"></span> <span aria-hidden="true">→</span></a>
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
  <div class="relative overflow-hidden pt-16 lg:pt-20">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <img class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" src="./assets/images/app-mockup-02.png" alt="">
      <div class="relative" aria-hidden="true">
        <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
      </div>
    </div>
  </div>
</div>

<div class="relative">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
</div>

<div class="bg-white pb-12 sm:pb-16 pt-16 sm:pt-16">
  <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
    <p class="text-base font-semibold leading-7 text-teal-600" translate="landing.app.hero.title"></p>
    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="landing.app.hero.subtitle"></h1>


    <div class="mt-10 grid grid-cols-6 gap-4 sm:mt-16 grid-rows-2">
      <div class="relative col-span-6 hover:cursor-pointer group" [routerLink]="['/xchangehub']" (click)="scrollToTop()">
        <div class="absolute inset-px rounded-lg bg-white lg:rounded-t-[2rem] group-hover:shadow-md"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-t-[calc(2rem+1px)]">
          <img class="h-80 object-cover object-left lg:object-right" src="./assets/images/app-mockup_en.png" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-teal-600 group-hover:font-bold" translate="landing.app.ecommerce.title"></h3>
            <p class="mt-2 text-sm/6 text-gray-600" translate="landing.hub.text"></p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-t-[2rem]"></div>
      </div>

      <div class="relative col-span-6 lg:col-span-2 hover:cursor-pointer group" [routerLink]="['/pricing']" (click)="scrollToTop()">
        <div class="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem] group-hover:shadow-md"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-bl-[calc(2rem+1px)]">
          <img class="h-80 object-cover object-left" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-teal-600 group-hover:font-bold" translate="menu.pricing"></h3>
            <p class="mt-2 max-w-lg text-sm/6 text-gray-600" translate="pricing.intro"></p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]"></div>
      </div>
      <div class="relative col-span-6 lg:col-span-2 hover:cursor-pointer group" [routerLink]="['/integrations']" (click)="scrollToTop()">
        <div class="absolute inset-px rounded-lg bg-white group-hover:shadow-md"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
          <img class="h-80 object-cover object-center" src="./assets/images/bento-01-integrations.png" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-teal-600 group-hover:font-bold" translate="menu.interfaces"></h3>
            <p class="mt-2 max-w-lg text-sm/6 text-gray-600" translate="integrations.text"></p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
      </div>
      <div class="relative col-span-6 lg:col-span-2 hover:cursor-pointer group" [routerLink]="['/about']" (click)="scrollToTop()">
        <div class="absolute inset-px rounded-lg bg-white group-hover:shadow-md max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
          <img class="h-80 object-cover object-center" src="./assets/images/bento-01-network.png" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-teal-600 group-hover:font-bold" translate="menu.aboutus"></h3>
            <p class="mt-2 max-w-lg text-sm/6 text-gray-600" translate="about-us.title.headline"></p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]"></div>
      </div>
    </div>
  </div>
</div>

<div class="relative">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
</div>

<div class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
      <h1 class="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl" translate="landing.partner.title"></h1>
      <p class="mt-4 text-base leading-7 text-gray-600 max-w-4xl text-pretty" translate="landing.partner.leading"></p>
      <p class="mt-4 text-base leading-7 text-gray-600 max-w-4xl text-pretty" translate="landing.partner.text"></p>
      <p class="mt-4 text-base leading-7 text-gray-600 max-w-4xl text-pretty" translate="landing.partner.second"></p>
    </div>

    <div class="absolute inset-0 -z-10 overflow-hidden">
      <svg class="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]" aria-hidden="true">
        <defs>
          <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
          <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
        </svg>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
      </svg>
    </div>

    <div class="relative mt-16">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
      <div class="relative flex justify-center">
        <span class="bg-white px-2 text-sm text-gray-500" translate="partner.more"></span>
      </div>
    </div>

    <div class="py-16">
      <carousel [counter]="false" [arrows]="false" [infinite]="true" [autoPlay]="true" [autoPlayAtStart]="true" [autoPlayInterval]="2500" [slideToShow]="3" [slideToScroll]="1" [displayAutoPlayControls]="false">
        <div class="carousel-slide flex justify-center items-center">
          <a href="https://acin.ch/" target="_blank"><img src="assets/logos/acin.svg" style="max-width: 200px"></a>
        </div>
        <div class="carousel-slide flex justify-center items-center">
          <a href="https://bonum-logistik.de/" target="_blank"><img src="assets/logos/bonum.jpg" style="max-width: 200px"></a>
        </div>
        <div class="carousel-slide flex justify-center items-center">
          <a href="https://gnlfootwear.com/" target="_blank"><img src="assets/logos/gnl.svg" style="max-width: 220px"></a>
        </div>
        <div class="carousel-slide flex justify-center items-center">
          <a href="https://www.gustagarden.com/?srsltid=AfmBOorZXaE_n0BYNT1NPfgZCQ3GaemJZdlx3JpPWSqYcvjRRwN5rZ48" target="_blank"><img src="assets/logos/gustagarden-logo_600x.svg" style="max-width: 200px"></a>
        </div>
        <div class="carousel-slide flex justify-center items-center">
          <a href="https://meinarztbedarf.com/" target="_blank"><img src="assets/logos/meinarztbedarf_logo_ohne_firma_1828x360.svg" style="max-width: 200px"></a>
        </div>
        <div class="carousel-slide flex justify-center items-center">
          <a href="https://www.mecon-gmbh.de/" target="_blank"><img src="assets/logos/mecon_logo_kurz_retina.png" style="max-width: 200px"></a>
        </div>
      </carousel>
    </div>


    <div class="relative mt-10">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
      <div class="relative flex justify-center">
        <span class="bg-white px-2 text-sm text-gray-500" translate="partner.headline"></span>
      </div>
    </div>


    <div class="mx-auto mt-16">
      <div class="w-full max-w-7xl">
        <div class="lg:pr-4">
          <div class="lg:max-w-lg">
            <a href="https://xendoit.de/" class="my-8 block" target="_blank"><img class="w-[18rem] max-w-none" src="assets/logos/xendoit.png" alt=""></a>
          </div>
        </div>
      </div>
      <div class="mx-auto w-full">
        <div class="max-w-7xl text-base/7 text-gray-700">
          <p translate="partner.xendoit.intro"></p>
          <p>
            <a (click)="toggleXendoit()" class="cursor-pointer text-teal-600" *ngIf="!showXendoit" translate="partner.button"></a>
          </p>
          <ng-container *ngIf="showXendoit">
            <p class="mt-4" translate="partner.xendoit.text1"></p>
            <p class="mt-2" translate="partner.xendoit.text2"></p>
            <p class="mt-2" translate="partner.xendoit.text3"></p>
          </ng-container>
        </div>
      </div>
    </div>


    <div class="mx-auto mt-16">
      <div class="w-full max-w-7xl">
        <div class="lg:pr-4">
          <div class="lg:max-w-lg">
            <a href="https://fulfillmenttools.com/" class="my-8 block" target="_blank"><img class="w-[18rem] max-w-none bg-gray-900" src="assets/images/partner/FT_Logo_2022_RZ_RGB.jpg" alt=""></a>
          </div>
        </div>
      </div>
      <div class="mx-auto w-full">
        <div class="max-w-7xl text-base/7 text-gray-700">
          <p translate="partner.fft.intro"></p>
          <p>
            <a (click)="toggleFft()" class="cursor-pointer text-teal-600 mt-2" *ngIf="!showFFT" translate="partner.button"></a>
          </p>
          <ng-container *ngIf="showFFT">
            <p class="mt-4" translate="partner.fft.text1"></p>
            <p class="mt-2" translate="partner.fft.text2"></p>
            <p class="mt-2" translate="partner.fft.text3"></p>
            <p class="mt-2" translate="partner.fft.text4"></p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="relative">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
</div>


<div class="bg-white px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
  <div class="mx-auto max-w-3xl text-center">
    <h2 class="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl" translate="landing.pricing.heading"></h2>
    <p class="mx-auto mt-6 max-w-3xl text-pretty text-lg/8 text-gray-600" translate="landing.pricing.text"></p>
  </div>

  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <div class="rounded-3xl p-8 ring-1 ring-gray-200 xl:p-10 text-center">
        <div class="flex items-center justify-between gap-x-4">
          <h3 id="tier-1" class="text-lg font-semibold leading-8 text-gray-900 mx-auto" translate="pricing.receipts.heading"></h3>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-600" translate="pricing.receipts.text"></p>
        <div class="mx-auto mt-4">
          <span class="text-4xl font-semibold tracking-tight text-gray-900" translate="pricing.receipts.start"></span>
          <span class="text-sm font-semibold leading-6 text-gray-600" translate="pricing.receipts.scale"></span>
        </div>
        <a [routerLink]="['/pricing']" fragment="tier1" aria-describedby="tier-freelancer" class="mt-6 block rounded-md cursor-pointer bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="pricing.receipts.details"></a>
      </div>
      <div class="rounded-3xl p-8 ring-1 ring-gray-200 xl:p-10 text-center">
        <div class="flex items-center justify-between gap-x-4">
          <h3 id="tier-2" class="text-lg font-semibold leading-8 text-gray-900 mx-auto" translate="pricing.base.heading"></h3>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-600" translate="pricing.base.text"></p>
        <div class="mx-auto mt-4">
          <span class="text-4xl font-semibold tracking-tight text-gray-900" translate="pricing.base.start"></span>
          <span class="text-sm font-semibold leading-6 text-gray-600" translate="pricing.base.scale"></span>
        </div>
        <a [routerLink]="['/pricing']" fragment="tier2" aria-describedby="tier-freelancer" class="mt-6 block rounded-md cursor-pointer bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="pricing.base.details"></a>
      </div>
      <div class="rounded-3xl p-8 ring-1 ring-gray-200 xl:p-10 text-center">
        <div class="flex items-center justify-between gap-x-4">
          <h3 id="tier-3" class="text-lg font-semibold leading-8 text-gray-900 mx-auto" translate="pricing.storage.heading"></h3>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-600" translate="pricing.storage.text"></p>
        <div class="mx-auto mt-4">
          <span class="text-4xl font-semibold tracking-tight text-gray-900" translate="pricing.storage.start"></span>
          <span class="text-sm font-semibold leading-6 text-gray-600" translate="pricing.storage.scale"></span>
        </div>
        <a [routerLink]="['/pricing']" fragment="tier3" aria-describedby="tier-enterprise" class="mt-6 block rounded-md cursor-pointer bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="pricing.storage.details"></a>
      </div>
    </div>
  </div>
</div>
