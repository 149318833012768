import {Component, OnInit} from '@angular/core';
import {SEOService} from './modules/service/seo-service/seo.service';
import {SeoBuilderService} from './modules/service/seo-builder/seo-builder.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    protected seoService: SEOService,
    protected seoBuilder: SeoBuilderService
  ) {
  }

  ngOnInit(): void {

    this.buildHeadInfo();
  }

  protected buildHeadInfo() {

    this.seoService.setMainTitle('SevData');
    this.seoService.setDefaultKeywords([
      'sevdata',
    ]);

    this.seoBuilder
      // TODO rh ALLES AN SEVDATA ANPASSEN
      .addMetaTag('og:url', 'https://www.sevdata.de/')
      .createLink('canonical', 'https://www.sevdata.de/')
      .addMetaTag('description', 'IT service provider for integrating systems in the logistics and e-commerce sector.')
      .addMetaTag('robots', 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1')
      .addMetaTag('google-site-verification', 'KSWALuFWSnsAwPU2Jhc7AyHs7G5_qmNDcw8qyo0u_Ag')
      .addMetaTag('keywords', this.seoService.getDefaultKeywords().join(', '))
      .addMetaTag('viewport', 'width=device-width, initial-scale=1.0, shrink-to-fit=no')
      .addMetaTag('msapplication-TileColor', '#000000')
      .addMetaTag('msapplication-config', '/favicon/browserconfig.xml')
      .addMetaTag('theme-color', '#000000')
      .addMetaTag('og:locale', 'de_DE')
      .addMetaTag('og:type', 'website')
      .addMetaTag('og:title', 'SevData')
      .addMetaTag('og:description', 'IT service provider for integrating systems in the logistics and e-commerce sector.')
      .addMetaTag('og:site_name', 'SevData')
      .createLink('icon', 'assets/icons/favicon.png', [ { name: 'type', value: 'image/x-icon' }])
      .createLink('apple-touch-icon', '/favicon/apple-touch-icon.png', [ { name: 'sizes', value: '180x180' } ])
      // .createLink('icon', '/favicon/airborne_logo-1-32x32.png', [ { name: 'type', value: 'image/png' }, { name: 'sizes', value: '32x32' } ])
      // .createLink('icon', '/favicon/airborne_logo-1-192x192.png', [ { name: 'type', value: 'image/png' }, { name: 'sizes', value: '192x192' } ])
      .createLink('mask-icon', '/favicon/safari-pinned-tab.svg', [ { name: 'color', value: '#000000'} ])
      .createLink('alternate', '/feed.xml', [ { name: 'type', value: 'application/rss+xml' } ])
      // .createLink('manifest', '/favicon/site.webmanifest')
      .build();
  }
}
