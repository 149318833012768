import {HeaderMenuItem} from '../config/header-menu-item';
import {HeaderMenuIcon} from '../config/header-menu-icon';
import {Injectable} from '@angular/core';
import {HeaderMenu} from '../config/header-menu';

@Injectable()
export class HeaderMenuBuilder {

  protected items: HeaderMenuItem[] = [];
  protected icon: HeaderMenuIcon;
  protected defaultMenu: HeaderMenu;

    constructor() {
      this.defaultMenu = this.clear()
          .addItem('menu.start', '/start')
          .addItem('menu.product', '/xchangehub')
          .addItem('menu.interfaces', '/integrations')
          .addItem('menu.pricing', '/pricing')
          .addItem('menu.aboutus', '/about')
          .buildConfig();
    }

  addItem(label: string, targetUrl: string, isExternal?: boolean): this {

    const item = new HeaderMenuItem();
    item.label = label;
    item.targetUrl = targetUrl;
    item.isExternal = isExternal;

    this.items.push(item);

    return this;
  }

  clear(): this {

    this.items = [];

    return this;
  }

  buildConfig(): HeaderMenu {

    const config = new HeaderMenu();
    config.icon = this.icon;
    config.items = this.items;

    return config;
  }

  getDefaultConfig() {
    return this.defaultMenu;
  }
}
