import { Component, OnInit } from '@angular/core';
import {HeaderMenuBuilder} from './builder/header-menu-builder';
import {HeaderMenu} from './config/header-menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  headerMenuConfig: HeaderMenu;
  showMobileMenu = false;

  constructor(
      protected headerMenuBuilder: HeaderMenuBuilder,
  ) {}

  ngOnInit(): void {

    this.headerMenuConfig = this.headerMenuBuilder.getDefaultConfig();
  }
}
