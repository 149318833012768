<div class="bg-slate-700 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl" translate="integrations.headline"></h2>
            <p class="mt-6 text-lg leading-8 text-gray-300" translate="integrations.text"></p>
        </div>
    </div>
</div>

<div class="bg-white px-6 py-16 lg:px-8" #target>
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.oms.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.oms.text"></p>
        <div class="mt-10 max-w-7xl">
            <table class="w-full text-left">
                <colgroup>
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                </colgroup>
                <thead>
                <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
                    <td class="p-0">

                    </td>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Xentral
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            weclapp
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            SAP <br> Business By Design
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Plenty Markets
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Fulfillment Tools
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="group">
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.orders"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.ordersConfirmation"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.deliveryNote"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.deliveryNoteConfirmation"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.purchaseOrder"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.receipt"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.returns"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.returnsReceipt"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.invoice"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.invoiceReceipt"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.stocks"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.article"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.address"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.marketplace.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.marketplace.text"></p>
        <div class="mt-10 max-w-7xl">

            <table class="w-full text-left">
                <colgroup>
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                </colgroup>
                <thead>
                <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
                    <td class="p-0"></td>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Refurbed</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">HGK</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Hood.de</div>
                    </th>
                </tr>
                </thead>
                <tbody class="group">
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.orders"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.ordersConfirmation"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                </tr>
                <!--
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.deliveryNote"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.deliveryNoteConfirmation"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.purchaseOrder"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.receipt"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.returns"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.returnsReceipt"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.invoice"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.invoiceReceipt"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                -->
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.stocks"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.article"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                </tr>
                <!--
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.address"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                -->
                </tbody>
            </table>

        </div>
    </div>
</div>

<div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.shops.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.shops.text"></p>
        <div class="mt-10 max-w-7xl">

            <table class="w-full text-left">
                <colgroup>
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                </colgroup>
                <thead>
                <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
                    <td class="p-0"></td>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Shopify</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Digistore24</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">CopeCart</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Dehner</div>
                    </th>
                </tr>
                </thead>
                <tbody class="group">
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.orders"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.ordersConfirmation"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <!--
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.deliveryNote"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.deliveryNoteConfirmation"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.purchaseOrder"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.receipt"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.returns"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.returnsReceipt"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.invoice"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.invoiceReceipt"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.stocks"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.import"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.article"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.address"></th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                -->
                </tbody>
            </table>


        </div>
    </div>
</div>

<div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.pim.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.pim.text"></p>
        <div class="mt-10 max-w-7xl">

            <table class="w-full text-left">
                <colgroup>
                    <col class="w-4/12">
                    <col class="w-4/12">
                    <col class="w-4/12">
                </colgroup>
                <thead>
                <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
                    <td class="p-0"></td>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Plytix
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Akeneo
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="group">
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.article"></th>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.export"></td>
                    <td class="p-2 text-center text-xs italic" translate="integrations.status.exportImport"></td>
                </tr>
                </tbody>
            </table>



        </div>
    </div>
</div>


<div class="overflow-hidden bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-7xl">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
            <div class="lg:pr-4 lg:pt-4">
                <div class="lg:max-w-lg">
                    
                    <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.flex.headline"></p>
                    <p class="mt-6 leading-8 text-gray-600" translate="integrations.flex.text"></p>
                    <div class="mt-8">
                        <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3/group/11/create/42" target="_blank" class="inline-flex rounded-md bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="integrations.flex.button"></a>
                    </div>
                </div>
            </div>
            <img src="./assets/images/app-mockup.png" alt="Product screenshot" class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0" width="2432" height="1442">
        </div>
    </div>
</div>