import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import paths from 'src/paths';
import {SEOService} from '../../service/seo-service/seo.service';
import AOS from "aos";


@Component({
    selector: 'air-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

    lang: string = 'en';
    fragment: string | null = null
    showXendoit: boolean = false;
    showFFT: boolean = false;

    constructor(
        protected seoService: SEOService,
        protected translate: TranslateService
    ) {
    }

    ngOnInit(): void {

        AOS.init();

        this.seoService.setPageTitle(this.translate.instant('title'));
        this.seoService.updateKeyWords(['SevData', 'Data Interchange', 'DIaaS', 'Xentral', 'eCommerce', 'Fulfillment', 'Integration', 'Data Exchange'])

        this.translate.onLangChange.subscribe((lang) => {
            this.lang = lang.lang;
        });
    }

    get paths() {
        return paths;
    }

    scrollToElement(target: HTMLDivElement) {
        target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }

    scrollToTop() {
        window.scrollTo({top: 0, behavior: 'auto'});
    }

    toggleXendoit() {
        this.showXendoit = !this.showXendoit;
    }

    toggleFft() {
        this.showFFT = !this.showFFT;
    }
}
