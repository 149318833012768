<div class="relative isolate overflow-hidden bg-white">
    <svg class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
        <defs>
            <pattern id="0787a7c5-978c-4f66-83c7-11c213f99cb7" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                <path d="M.5 200V.5H200" fill="none" />
            </pattern>
        </defs>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" />
    </svg>
    <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-shrink-0 lg:pt-8">
            <div class="flex items-center">
                <img class="h-11" src="assets/images/xchange-hub.png" alt="XChangeHub">
                <span class="text-2xl ml-2"><b>XChange</b>Hub</span>
            </div>
            <h1 class="mt-10 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl" translate="products.xchange.overview.details"></h1>
            <p class="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8" translate="products.xchange.overview.text1"></p>
            <p class="mt-2 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8" translate="products.xchange.overview.text2">

            </p>
        </div>
        <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                <div class="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                    <img src="./assets/images/app-mockup-02.png" alt="App screenshot" width="2432" height="1442" class="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="relative bg-white">
    <div class="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div class="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
            <div class="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
                <img class="absolute inset-0 h-full w-full bg-gray-50 object-cover" src="./assets/images/fulfillment.jpg" alt="">
            </div>
        </div>
        <div class="px-6 lg:contents">
            <div class="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
                <h1 class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl" translate="products.xchange.fulfilment.heading"></h1>
                <div class="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none">
                    <p translate="products.xchange.fulfilment.text1"></p>
                    <p class="mt-6" translate="products.xchange.fulfilment.text2"></p>
                    <p class="mt-6" translate="products.xchange.fulfilment.text3"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overflow-hidden bg-white mt-32">
    <div class="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <div class="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block"></div>
        <div class="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
            <div>
                <h3 class="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl" translate="products.xchange.ecommerce.heading"></h3>
            </div>
        </div>
        <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="relative lg:col-start-2 lg:row-start-1">
                <svg class="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                    <defs>
                        <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                </svg>
                <div class="relative mx-auto max-w-prose text-base lg:max-w-none">
                    <figure>
                        <div class="aspect-h-7 aspect-w-12 lg:aspect-none">
                            <img class="rounded-lg object-cover object-center shadow-lg" src="./assets/images/ecommerce.jpg" style="height: 600px"/>
                        </div>
                    </figure>
                </div>
            </div>
            <div class="mt-8 lg:mt-0">
                <div class="prose prose-indigo mx-auto mt-5 text-base leading-7 text-gray-700">
                    <p translate="products.xchange.ecommerce.text1"></p>
                    <p class="mt-6" translate="products.xchange.ecommerce.text2"></p>
                    <p class="mt-6" translate="products.xchange.ecommerce.text3"></p>
                    <p class="mt-6" translate="products.xchange.ecommerce.text4"></p>
                    <p class="mt-6" translate="products.xchange.ecommerce.text5"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h1 class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl" translate="products.xchange.platform.heading"></h1>
            <div class="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none">
                <div>
                    <p translate="products.xchange.platform.text1"></p>
                    <p class="mt-8" translate="products.xchange.platform.text2"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="relative overflow-hidden pt-16 lg:pt-20">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <img class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" src="./assets/images/app-mockup_03.png" alt="">
            <div class="relative" aria-hidden="true">
                <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
            </div>
        </div>
    </div>
</div>
