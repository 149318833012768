<header class="bg-white">
    <div class="hidden lg:flex mx-auto max-w-7xl items-center justify-between py-4 pb-2 lg:px-8">
        <div class="flex items-center space-x-4">
            <img src="./assets/logos/sevdata.png" class="h-8 w-auto">
        </div>
        <div class="flex items-center">
            <app-language-switcher></app-language-switcher>
            <a target="_blank" class="text-sm font-semibold leading-6 text-gray-900 hover:text-teal-700 ml-10" href="https://sevdata.atlassian.net/servicedesk/customer/portal/3" translate="menu.support"></a>
            <a href="https://app.sevdata.io/" target="_blank" class="text-sm font-semibold leading-6 text-gray-900 hover:text-teal-700 ml-4"><span translate="menu.login" class="mr-1.5"></span> <span aria-hidden="true">&rarr;</span></a>
        </div>
    </div>
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div class="flex items-center gap-x-12">
            <div class="flex lg:hidden lg:gap-x-12 space-x-4">
                <img src="./assets/icons/SevData_Signet_farbe.png" class="h-8 w-auto"> <span class="inline-block text-lg font-semibold text-gray-800">Sevdata GmbH</span>
            </div>
            <div class="hidden lg:flex lg:gap-x-12">
                <ng-container *ngFor="let item of headerMenuConfig.items">
                    <a *ngIf="!item.isExternal" class="text-sm leading-6 text-gray-900 hover:text-teal-700" [routerLink]="[item.targetUrl]" [translate]="item.label" routerLinkActive="text-teal-700 font-bold"></a>
                </ng-container>
            </div>
        </div>
        <div class="flex lg:hidden">
            <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:text-teal-700" (click)="showMobileMenu = true">
                <span class="sr-only">Open main menu</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </button>
        </div>
        <div class="hidden lg:flex">
        </div>
    </nav>
    <!-- Mobile menu, show/hide based on menu open state. -->
    <div class="lg:hidden" role="dialog" aria-modal="true" *ngIf="showMobileMenu">
        <!-- Background backdrop, show/hide based on slide-over state. -->
        <div class="fixed inset-0 z-10"></div>
        <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div class="flex items-center justify-between">
                <a [routerLink]="['/']" class="-m-1.5 p-1.5 flex space-x-4">
                    <img src="./assets/logos/sevdata.png" class="h-8 w-auto"> <span class="inline-block text-lg font-semibold text-gray-800">Sevdata GmbH</span>
                </a>
                <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700 hover:text-teal-700" (click)="showMobileMenu = false">
                    <span class="sr-only">Close menu</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div class="mt-6 flow-root">
                <div class="-my-6 divide-y divide-gray-500/10">
                    <div class="space-y-2 py-6">
                        <ng-container *ngFor="let item of headerMenuConfig.items">
                            <a *ngIf="!item.isExternal" (click)="showMobileMenu = false" class="-mx-3 block rounded-lg px-3 py-2 hover:text-teal-700 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" [routerLink]="[item.targetUrl]" [translate]="item.label" routerLinkActive="text-teal-700"></a>
                            <a target="_blank" *ngIf="item.isExternal" class="-mx-3 block rounded-lg px-3 hover:text-teal-700 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" [href]="item.targetUrl" [translate]="item.label"></a>
                        </ng-container>
                    </div>
                    <div class="py-6">
                        <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3" target="_blank" class="-mx-3 block rounded-lg px-3 hover:text-teal-700 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" translate="menu.support"></a>
                        <a href="https://app.sevdata.io/" target="_blank" class="-mx-3 block rounded-lg px-3 hover:text-teal-700 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" translate="menu.login"></a>
                        <div class="mt-10">
                            <app-language-switcher></app-language-switcher>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>