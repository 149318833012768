import { Component, OnInit } from '@angular/core';
import {SEOService} from '../../service/seo-service/seo.service';

@Component({
  selector: 'app-imprint-page',
  templateUrl: './imprint-page.component.html',
  styleUrls: ['./imprint-page.component.scss']
})
export class ImprintPageComponent implements OnInit {

  constructor(protected seoService: SEOService) {}

  ngOnInit(): void {

    this.seoService.setPageTitle('menu.imprint');
    this.seoService.updateKeyWords(['impressum', 'imprint'])
  }
}
