import {Component, OnInit} from '@angular/core';
import {SEOService} from '../../service/seo-service/seo.service';

@Component({
  selector: 'air-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss']
})
export class PrivacyPageComponent implements OnInit {

  constructor(
    protected seoService: SEOService,
    ) {
  }

  ngOnInit(): void {

    this.seoService.setPageTitle('menu.privacy');
    this.seoService.updateKeyWords(['datenschutz', 'privacy'])
  }
}
