<!-- <rh-navbar></rh-navbar>
<div class="content-margin-top">
  <router-outlet></router-outlet>
</div>
<rh-footer></rh-footer>
-->


<div class="bg-white">
  <header>
    <app-menu></app-menu>
  </header>

  <main>
    <router-outlet></router-outlet>
    <!-- Hero section -->
  </main>

  <footer class="bg-gray-50" aria-labelledby="footer-heading">
    <app-footer></app-footer>
  </footer>
</div>