<div class="bg-slate-700 py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-7xl lg:mx-0">
      <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl" translate="pricing.heading"></h2>
      <p class="mt-6 text-lg leading-8 text-gray-300" translate="pricing.text"></p>
    </div>
  </div>
</div>

<div class="bg-white py-12 sm:py-16">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <div class="rounded-3xl p-8 ring-1 ring-gray-200 xl:p-10">
        <div class="flex items-center justify-between gap-x-4">
          <h3 id="tier-1" class="text-lg font-semibold leading-8 text-gray-900" translate="pricing.receipts.heading"></h3>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-600" translate="pricing.receipts.text"></p>
        <p class="mt-6 flex items-baseline gap-x-1">
          <span class="text-4xl font-semibold tracking-tight text-gray-900" translate="pricing.receipts.start"></span>
          <span class="text-sm font-semibold leading-6 text-gray-600" translate="pricing.receipts.scale"></span>
        </p>
        <a (click)="scrollToElement(tier1)" aria-describedby="tier-freelancer" class="mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-teal-600 ring-1 ring-inset ring-teal-200 hover:ring-teal-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 cursor-pointer" translate="pricing.receipts.details"></a>
      </div>
      <div class="rounded-3xl p-8 ring-1 ring-gray-200 xl:p-10">
        <div class="flex items-center justify-between gap-x-4">
          <h3 id="tier-2" class="text-lg font-semibold leading-8 text-gray-900" translate="pricing.base.heading"></h3>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-600" translate="pricing.base.text"></p>
        <p class="mt-6 flex items-baseline gap-x-1">
          <span class="text-4xl font-semibold tracking-tight text-gray-900" translate="pricing.base.start"></span>
          <span class="text-sm font-semibold leading-6 text-gray-600" translate="pricing.base.scale"></span>
        </p>
        <a (click)="scrollToElement(tier2)" aria-describedby="tier-freelancer" class="mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-teal-600 ring-1 ring-inset ring-teal-200 hover:ring-teal-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 cursor-pointer" translate="pricing.base.details"></a>
      </div>
      <div class="rounded-3xl p-8 ring-1 ring-gray-200 xl:p-10">
        <div class="flex items-center justify-between gap-x-4">
          <h3 id="tier-3" class="text-lg font-semibold leading-8 text-gray-900" translate="pricing.storage.heading"></h3>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-600" translate="pricing.storage.text"></p>
        <p class="mt-6 flex items-baseline gap-x-1">
          <span class="text-4xl font-semibold tracking-tight text-gray-900" translate="pricing.storage.start"></span>
          <span class="text-sm font-semibold leading-6 text-gray-600" translate="pricing.storage.scale"></span>
        </p>
        <a (click)="scrollToElement(tier3)" aria-describedby="tier-enterprise" class="mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-teal-600 ring-1 ring-inset ring-teal-200 hover:ring-teal-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 cursor-pointer" translate="pricing.storage.details"></a>
      </div>
    </div>
  </div>
</div>


<div class="bg-white py-12 sm:py-16">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-7xl text-left" #tier1 id="tier1">
      <h2 class="text-base font-semibold leading-7 text-teal-600" translate="pricing.receipts.heading"></h2>
      <p class="mt-2 text-balance text-3xl font-semibold tracking-tight text-gray-900" translate="pricing.receipts.package"></p>
    </div>
    <p class="mx-auto mt-6 max-w-7xl text-pretty text-left text-lg font-medium text-gray-600 sm:text-xl/8" translate="pricing.receipts.expl"></p>

    <p class="mt-10 text-balance text-xl font-semibold tracking-tight text-gray-900" translate="pricing.receipts.blocks"></p>

    <div class="mt-6">
      <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 mr-2 mb-2 font-medium text-gray-600" translate="pricing.receipts.comp1"></span>
      <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 mr-2 mb-2 font-medium text-gray-600" translate="pricing.receipts.comp2"></span>
      <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 mr-2 mb-2 font-medium text-gray-600" translate="pricing.receipts.comp3"></span>
      <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 mr-2 mb-2 font-medium text-gray-600" translate="pricing.receipts.comp4"></span>
      <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 mr-2 mb-2 font-medium text-gray-600" translate="pricing.receipts.comp5"></span>
    </div>

    <div class="isolate mt-20 block">
      <p class="mt-2 text-balance text-xl font-semibold tracking-tight text-gray-900" translate="pricing.receipts.priceScale"></p>

      <div class="relative -mx-8">
        <table class="w-full table-fixed border-separate border-spacing-x-8 text-left">
          <colgroup>
            <col class="w-1/3">
            <col class="w-1/3">
            <col class="w-1/3">
          </colgroup>
          <thead>
          <tr>
            <th scope="col" class="py-4">
              <div class="text-sm font-semibold leading-7 text-gray-900" translate="pricing.receipts.amount"></div>
            </th>
            <th scope="col" class="py-4 text-center">
              <div class="text-sm font-semibold leading-7 text-gray-900" translate="pricing.receipts.packageReceipte"></div>
            </th>
            <th scope="col" class="py-4 text-center">
              <div class="text-sm font-semibold leading-7 text-gray-900" translate="pricing.receipts.packageReturn"></div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              < 250
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">50€</div>
            </td>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">62,50€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              250 - 1000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">150€</div>
            </td>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">180€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              1001 - 2500
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">300€</div>
            </td>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">350€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              2501 - 5000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">500€</div>
            </td>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">550€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              > 5000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500" translate="pricing.receipts.onRequest"></div>
            </td>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500" translate="pricing.receipts.onRequest"></div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<div class="bg-white py-12 sm:py-16">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-7xl text-left" #tier2 id="tier2">
      <h2 class="text-base font-semibold leading-7 text-teal-600" translate="pricing.base.heading"></h2>
      <p class="mt-2 text-balance text-3xl font-semibold tracking-tight text-gray-900" translate="pricing.base.package"></p>
    </div>
    <p class="mx-auto mt-6 max-w-7xl text-pretty text-left text-lg font-medium text-gray-600 sm:text-xl/8" translate="pricing.base.expl"></p>

    <p class="mt-10 text-balance text-xl font-semibold tracking-tight text-gray-900" translate="pricing.base.blocks"></p>

    <div class="mt-6">
      <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 mr-2 mb-2 font-medium text-gray-600" translate="pricing.base.comp1"></span>
      <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 mr-2 mb-2 font-medium text-gray-600" translate="pricing.base.comp2"></span>
    </div>

    <div class="isolate mt-20 block">
      <p class="mt-2 text-balance text-xl font-semibold tracking-tight text-gray-900" translate="pricing.base.priceScale"></p>

      <div class="relative -mx-8">
        <table class="w-full table-fixed border-separate border-spacing-x-8 text-left">
          <colgroup>
            <col class="w-1/2">
            <col class="w-1/2">
          </colgroup>
          <thead>
          <tr>
            <th scope="col" class="py-4">
              <div class="text-sm font-semibold leading-7 text-gray-900" translate="pricing.base.amount"></div>
            </th>
            <th scope="col" class="py-4 text-center">
              <div class="text-sm font-semibold leading-7 text-gray-900" translate="pricing.base.packagePrice"></div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              < 10.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">150€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              10.000 - 15.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">225€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              15.001 - 20.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">280€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              20.001 - 25.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">350€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              25.001 - 30.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">390€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              30.001 - 35.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">455€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              35.001 - 40.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">480€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              40.001 - 45.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">540€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              45.001 - 50.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">550€</div>
            </td>
          </tr>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              > 50.000
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500" translate="pricing.base.onRequest"></div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<div class="bg-white py-12 sm:py-16">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-7xl text-left" #tier3 id="tier3">
      <h2 class="text-base font-semibold leading-7 text-teal-600" translate="pricing.storage.heading"></h2>
      <p class="mt-2 text-balance text-3xl font-semibold tracking-tight text-gray-900" translate="pricing.storage.package"></p>
    </div>
    <p class="mx-auto mt-6 max-w-7xl text-pretty text-left text-lg font-medium text-gray-600 sm:text-xl/8" translate="pricing.storage.expl"></p>

    <p class="mt-10 text-balance text-xl font-semibold tracking-tight text-gray-900" translate="pricing.storage.blocks"></p>

    <div class="mt-6">
      <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 mr-2 mb-2 font-medium text-gray-600" translate="pricing.storage.comp1"></span>
    </div>

    <div class="isolate mt-10 block">
      <p class="mt-2 text-balance text-xl font-semibold tracking-tight text-gray-900" translate="pricing.storage.priceScale"></p>

      <div class="relative -mx-8">
        <table class="w-full table-fixed border-separate border-spacing-x-8 text-left">
          <colgroup>
            <col class="w-1/2">
            <col class="w-1/2">
          </colgroup>
          <thead>
          <tr>
            <th scope="col" class="py-4">
              <div class="text-sm font-semibold leading-7 text-gray-900" translate="pricing.storage.amount"></div>
            </th>
            <th scope="col" class="py-4 text-center">
              <div class="text-sm font-semibold leading-7 text-gray-900" translate="pricing.storage.price"></div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row" class="py-4 text-sm font-normal leading-6 text-gray-900">
              1
              <div class="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
            </th>
            <td class="px-6 py-4 xl:px-8">
              <div class="text-center text-sm leading-6 text-gray-500">1,00€</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="relative bg-gray-900">
  <div class="relative h-80 overflow-hidden bg-teal-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
    <img class="h-full w-full object-cover" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=14b8a6&sat=-100&blend-mode=multiply" alt="">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 926 676" aria-hidden="true" class="absolute left-24 -bottom-24 w-[57.875rem] transform-gpu blur-[118px]">
      <path fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)" fill-opacity=".4" d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z" />
      <defs>
        <linearGradient id="60c3c621-93e0-4a09-a0e6-4c228a0116d8" x1="926.392" x2="-109.635" y1=".176" y2="321.024" gradientUnits="userSpaceOnUse">
          <stop stop-color="#776FFF"></stop>
          <stop offset="1" stop-color="#FF4694"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="relative mx-auto max-w-7xl py-24 sm:py-32 lg:py-40 lg:px-8">
    <div class="pr-6 pl-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
      <h2 class="text-base font-semibold leading-7 text-teal-400" translate="pricing.support.heading"></h2>
      <p class="mt-2 text-4xl font-bold tracking-tight text-white" translate="pricing.support.question"></p>
      <p class="mt-6 text-base leading-7 text-gray-300" translate="pricing.support.text"></p>
      <div class="mt-8">
        <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3/group/11/create/42" target="_blank" class="inline-flex rounded-md bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="integrations.flex.button"></a>
      </div>
    </div>
  </div>
</div>