import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  currentLanguage: string;

  constructor(
    protected translateService: TranslateService,
  ) { }

  ngOnInit(): void {

    this.currentLanguage = this.translateService.getBrowserLang() || 'en';
    this.translateService.onDefaultLangChange.subscribe(language => this.currentLanguage = language.lang);

    this.setDefaultBrowserLanguage();
  }

  setLanguage(language: string) {
    this.translateService.use(language);
    this.translateService.setDefaultLang(language);
  }

  protected setDefaultBrowserLanguage() {

    if (this.currentLanguage !== 'en' && this.currentLanguage !== 'de') {
      this.currentLanguage = 'en';
    }

    this.setLanguage(this.currentLanguage);
  }
}
