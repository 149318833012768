<div class="bg-slate-700 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl" translate="about-us.company.headline"></h2>
            <p class="mt-6 text-lg leading-8 text-gray-300" translate="about-us.title.headline"></p>
        </div>
    </div>
</div>

<div class="bg-white py-24 md:py-32 lg:py-40">
    <div class="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="about-us.team.headline"></h2>
            <p class="mt-6 text-lg leading-8 text-gray-600" translate="about-us.company.text6"></p>
            <p class="mt-2 text-lg leading-8 text-gray-600" translate="about-us.company.text7"></p>
        </div>
        <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
            <li>
                <img class="aspect-[3/2] w-full rounded-2xl object-cover" src="/assets/images/team/ds.jpg" alt="">
                <h3 class="mt-6 text-lg font-semibold leading-8 text-gray-900" translate="about-us.daniel.name"></h3>
                <p class="text-base leading-7 text-gray-600" translate="about-us.daniel.subline"></p>
                <p class="mt-4 text-base leading-7 text-gray-600" translate="about-us.company.text1"></p>
                <p class="mt-2 text-base leading-7 text-gray-600" translate="about-us.company.text2"></p>
            </li>

            <li>
                <img class="aspect-[3/2] w-full rounded-2xl object-cover" src="/assets/images/team/sm.jpg" alt="">
                <h3 class="mt-6 text-lg font-semibold leading-8 text-gray-900" translate="about-us.sven.name"></h3>
                <p class="text-base leading-7 text-gray-600" translate="about-us.sven.subline"></p>
                <p class="mt-4 text-base leading-7 text-gray-600" translate="about-us.company.text3"></p>
                <p class="mt-2 text-base leading-7 text-gray-600" translate="about-us.company.text4"></p>
            </li>

            <li>
                <img class="aspect-[3/2] w-full rounded-2xl object-cover" src="/assets/images/team/cw.jpg" alt="">
                <h3 class="mt-6 text-lg font-semibold leading-8 text-gray-900" translate="about-us.christoph.name"></h3>
                <p class="text-base leading-7 text-gray-600" translate="about-us.christoph.subline"></p>
                <p class="mt-4 text-base leading-7 text-gray-600" translate="about-us.company.text5"></p>
            </li>

            <li>
                <img class="aspect-[3/2] w-full rounded-2xl object-cover" src="/assets/images/team/vw.jpg" alt="">
                <h3 class="mt-6 text-lg font-semibold leading-8 text-gray-900" translate="about-us.vivien.name"></h3>
                <p class="text-base leading-7 text-gray-600" translate="about-us.vivien.subline"></p>
                <p class="mt-4 text-base leading-7 text-gray-600" translate="about-us.company.text8"></p>
            </li>
        </ul>
    </div>
</div>

<div class="bg-white py-12 sm:py-16">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <p class="text-base font-semibold leading-7 text-teal-600" translate="about-us.why.philosophy"></p>
            <h1 class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl" translate="about-us.why.headline"></h1>
            <div class="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div>
                    <p translate="about-us.why.bullet1"></p>
                    <p class="mt-8" translate="about-us.why.bullet2"></p>
                </div>
                <div>
                    <p translate="about-us.why.bullet3"></p>
                </div>
            </div>
            <div class="mt-10 flex">
                <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3/group/11/create/42" target="_blank" class="inline-flex rounded-md bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="integrations.flex.button"></a>
            </div>
        </div>
    </div>
</div>
