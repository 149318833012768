import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SEOService} from './seo-service/seo.service';
import {SeoBuilderService} from './seo-builder/seo-builder.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        SEOService,
        SeoBuilderService,
    ]
})
export class ServiceModule {
}
