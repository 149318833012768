import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SEOService} from "../../service/seo-service/seo.service";

@Component({
    selector: 'app-references-page',
    templateUrl: './pricing-page.component.html',
    styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit {

    constructor(
        protected seoService: SEOService,
        protected translate: TranslateService
    ) {
    }

    ngOnInit(): void {

        this.seoService.setPageTitle('menu.pricing');
        this.seoService.updateKeyWords(['Pricing', 'Preise']);
    }

    scrollToElement(target: HTMLDivElement) {
        target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
}
