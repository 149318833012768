import {NgModule} from '@angular/core';


import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {LanguageSwitcherComponent} from './language-switcher/language-switcher.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        LanguageSwitcherComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        LanguageSwitcherComponent,
    ]
})
export class SharedModule {
}
