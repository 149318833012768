import {NgModule} from '@angular/core';
import {CarouselModule} from 'ngx-carousel-ease';
import {DecorationsModule} from '../decorations/decorations.module';
import {LandingPageComponent} from "./landing-page/landing-page.component";
import {SharedModule} from "../shared/shared.module";
import {PrivacyPageComponent} from "./privacy-page/privacy-page.component";
import {ImprintPageComponent} from "./imprint-page/imprint-page.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {PricingPageComponent} from './pricing-page/pricing-page.component';
import {TranslateModule} from "@ngx-translate/core";
import {AboutUsComponent} from './about-us/about-us.component';
import {IntegrationsPageComponent} from './integrations-page/integrations-page.component';
import {XchangehubPageComponent} from './xchangehub-page/xchangehub-page.component';

@NgModule({
    declarations: [
        LandingPageComponent,
        PrivacyPageComponent,
        ImprintPageComponent,
        PricingPageComponent,
        AboutUsComponent,
        IntegrationsPageComponent,
        XchangehubPageComponent,
    ],
    providers: [],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        TranslateModule,
        DecorationsModule,
        CarouselModule,
    ]
})
export class PagesModule {
}
