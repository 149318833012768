import {Component, OnInit} from '@angular/core';
import {HeaderMenuBuilder} from '../builder/header-menu-builder';
import {HeaderMenu} from '../config/header-menu';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  headerMenuConfig: HeaderMenu;

  constructor(
      protected headerMenuBuilder: HeaderMenuBuilder,
  ) {}

  ngOnInit(): void {

    this.headerMenuConfig = this.headerMenuBuilder.getDefaultConfig();
  }
}
