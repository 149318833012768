import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-lines',
    templateUrl: './lines.component.html',
    styleUrls: ['./lines.component.scss']
})
export class LinesComponent implements OnInit {

    @Input() amountOfLines: number

    lines: LineConfig[] = [];

    constructor() {
    }

    ngOnInit(): void {

        for (let i = 0; i < this.amountOfLines; i++) {
            this.lines.push(new LineConfig(
                this.getLineColorClass(),
                this.getLineOffset(),
                this.getLineDelay(),
                this.getLineDirection(),
                this.getAnimationSpeed()
            ));
        }

    }

    private getLineColorClass() {
        const colors = [
            'teal',
            'red',
            'yellow',
            'green',
            'blue',
            'purple'
        ];

        return colors[Math.floor(Math.random() * colors.length)];
    }

    getLineDelay() {

        const delays = [
            'delay-1',
            'delay-2',
            'delay-3',
            'delay-4',
            'delay-5',
            'delay-6',
            'delay-7',
            'delay-8',
            'delay-9',
            'delay-10',
            'delay-11',
            'delay-12'
        ];

        return delays[Math.floor(Math.random() * delays.length)];
    }

    private getLineOffset() {
        const offsets = [
            '0',
            '3%',
            '6%',
            '9%',
            '10%',
            '12%',
            '15%',
            '18%',
            '20%',
            '22%',
            '25%',
            '28%',
            '30%',
            '32%',
            '35%',
            '38%',
            '40%',
            '42%',
            '45%',
            '48%',
            '50%',
            '52%',
            '55%',
            '58%',
            '60%',
            '62%',
            '65%',
            '68%',
            '70%',
            '72%',
            '75%',
            '78%',
            '80%',
            '82%',
        ];

        return offsets[Math.floor(Math.random() * offsets.length)];
    }

    private getLineDirection() {
        const directions = [
            'left',
            'right'
        ];

        return directions[Math.floor(Math.random() * directions.length)];
    }

    getClasses(line: LineConfig) {
        return [line.direction, line.color, line.delay, line.speed];
    }

    private getAnimationSpeed() {

        const speeds = [
            'speed-1',
            'speed-2',
            'speed-3',
            'speed-4',
            'speed-5',
            'speed-6'
        ];

        return speeds[Math.floor(Math.random() * speeds.length)];
    }
}

class LineConfig {
    constructor(
        public color: string,
        public top: string,
        public delay: string,
        public direction: string = 'left',
        public speed: string = 'speed-1'
    ) {
    }
}