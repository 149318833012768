<footer class="bg-white">
  <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
    <nav class="-mb-6 flex flex-wrap justify-center gap-x-12 gap-y-3 text-sm/6" aria-label="Footer">
      <ng-container *ngFor="let item of headerMenuConfig.items">
        <a [routerLink]="[item.targetUrl]" [translate]="item.label" class="text-gray-600 hover:text-gray-900"></a>
      </ng-container>
      <a [routerLink]="['/imprint']" class="text-gray-600 hover:text-gray-900" translate="menu.imprint"></a>
      <a [routerLink]="['/privacy']" class="text-gray-600 hover:text-gray-900" translate="menu.privacy"></a>
      <a href="https://www.linkedin.com/company/sevdata/" class="text-gray-600 hover:text-gray-900">LinkedIn</a>
    </nav>
    <p class="mt-10 text-center text-sm/6 text-gray-600">&copy; 2024 SevData GmbH. All rights reserved.</p>
  </div>
</footer>