<div class="carousel-container" #carouselContainer>
  <div class="banner-container">
    @if (arrows) {
    <div class="btn-container">
      <button
        class="prev"
        aria-label="previous button navigation"
        (click)="slider?.prev()"
        [disabled]="
          !infinite &&
          ((slider?.currentSlide === 0 &&
            (slider?.currentTranslation || 0) >= 0) ||
            carousel?.numberDots === 1)
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="16"
          width="16"
          viewBox="0 0 512 512"
        >
          <path
            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"
          />
        </svg>
      </button>
      <button
        class="next"
        aria-label="next button navigation"
        (click)="slider?.next()"
        [disabled]="
          !infinite &&
          ((slider?.currentSlide === (carousel?.numberDots || 0) - 1 &&
            (slider?.currentTranslation || 0) <=
              -(carousel?.maxScrollableContent || 0)) ||
            carousel?.numberDots === 1)
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="16"
          width="16"
          viewBox="0 0 512 512"
        >
          <path
            d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
          />
        </svg>
      </button>
    </div>
    } @if (autoPlay && displayAutoPlayControls) {
    <div class="autoplay-container">
      <button
        class="play"
        [disabled]="slider?.playActive || slider?.playButtonDisabled"
        (click)="slider?.launchAutoPlay()"
        aria-label="play button navigation"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path
            d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
          />
        </svg>
      </button>
      <button
        class="pause"
        [disabled]="!slider?.playActive"
        (click)="slider?.stopAutoPlay()"
        aria-label="pause button navigation"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"
          />
        </svg>
      </button>
    </div>
    } @if (counter) {
    <div class="counter">
      <p>
        {{ (slider?.currentSlide || 0) + 1 }} {{ counterSeparator }}
        {{ carousel?.numberDots }}
      </p>
    </div>
    }
  </div>

  <div
    class="slides-container"
    [class.dragging]="slider?.dragging"
    (mousedown)="slider?.dragStart($event)"
    (touchstart)="slider?.dragStart($event)"
    (mousemove)="slider?.dragMove($event)"
    (touchmove)="slider?.dragMove($event)"
    (touchend)="slider?.dragStop($event); this.slider?.relaunchAutoPlay()"
  >
    <ng-content></ng-content>
  </div>

  @if (dots) {
  <div class="bullets-container">
    <button
      class="bullet"
      aria-label="bullet point navigation"
      *ngFor="let bullet of carousel?.arrayNumberDots"
      [class.current]="bullet === slider?.currentSlide"
      (click)="slider?.goTo(bullet)"
    ></button>
  </div>
  }
</div>
