import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IntegrationsPageComponent} from './modules/pages/integrations-page/integrations-page.component';
import {LandingPageComponent} from "./modules/pages/landing-page/landing-page.component";
import {PrivacyPageComponent} from "./modules/pages/privacy-page/privacy-page.component";
import {ImprintPageComponent} from "./modules/pages/imprint-page/imprint-page.component";
import {PricingPageComponent} from "./modules/pages/pricing-page/pricing-page.component";
import {AboutUsComponent} from './modules/pages/about-us/about-us.component';
import {XchangehubPageComponent} from './modules/pages/xchangehub-page/xchangehub-page.component';

const routes: Routes = [
    {
        path: 'start',
        component: LandingPageComponent,
        data: {animation: 'landingPage'}
    },
    {
        path: 'privacy',
        component: PrivacyPageComponent,
        data: {animation: 'privacyPage'}
    },
    {
        path: 'imprint',
        component: ImprintPageComponent,
        data: {animation: 'imprintPage'}
    },
    {
        path: 'integrations',
        component: IntegrationsPageComponent,
        data: {animation: 'integrationsPage'}
    },
    {
        path: 'xchangehub',
        component: XchangehubPageComponent,
        data: {animation: 'xchangehubPage'}
    },
    {
        path: 'pricing',
        component: PricingPageComponent
    },
    {
        path: 'about',
        component: AboutUsComponent,
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'start'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
