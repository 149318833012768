import { NgModule } from '@angular/core';
import {CarouselModule} from 'ngx-carousel-ease';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MenuModule} from "./modules/menu/menu.module";
import {PagesModule} from "./modules/pages/pages.module";
import {ServiceModule} from "./modules/service/service.module";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {ResolverModule} from "./modules/resolver/resolver.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        MenuModule,
        PagesModule,
        ServiceModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ResolverModule,
        CarouselModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
